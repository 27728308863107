import React from "react"
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap"

const Faq1 = () => {
  return (
    <section id="faq-section1" className="pt-5">
      <div className="container h-100  mt-5 py-5 ">
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-center flex-column align-items-center mt-5"
          >
            <h1>FAQ</h1>
            <h6>
              Psst... hey you! This is a pro tip for mobile SuperBetter players:
              if you've never checked out the online version, you're missing
              some of our most powerful features! You've got a Secret Lab
              online, bonus ally features, and more. Be sure to log in at
              www.superbetter.com to get even more of your SuperBetter
              experience.
            </h6>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Faq1
