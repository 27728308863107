import React from "react"
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap"

const Faq2 = () => {
  return (
    <section id="faq-section2">
      <div className="container h-100 py-3">
        <Row>
          <Col md="12" className="pt-5 mt-3 pb-5">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="custom-tabs">
                <Col md="3" sm={12} className="tabs">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        Understanding SuperBetter
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Play SuperBetter</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Allies</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Account & Privacy</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col className="tab-panel " md="9" sm="12">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Accordion className="custom-accordion" defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            What is SuperBetter?
                          </Accordion.Header>
                          <Accordion.Body>
                            SuperBetter is a tool created by game designers and
                            backed by science. Playing SuperBetter helps build
                            personal resilience: the ability to stay strong,
                            motivated, and optimistic even in the face of
                            difficult challenges. Resilience has a powerful
                            effect on health—by boosting physical and emotional
                            well-being. Resilience also helps you achieve your
                            life goals—by strengthening your social support and
                            increasing your stamina, willpower, and focus. Every
                            aspect of SuperBetter is designed to harness the
                            power of positive emotions and social connection to
                            help you improve your life.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                          Who is SuperBetter for?
                          </Accordion.Header>
                          <Accordion.Body>
                            SuperBetter is a tool created by game designers and
                            backed by science. Playing SuperBetter helps build
                            personal resilience: the ability to stay strong,
                            motivated, and optimistic even in the face of
                            difficult challenges. Resilience has a powerful
                            effect on health—by boosting physical and emotional
                            well-being. Resilience also helps you achieve your
                            life goals—by strengthening your social support and
                            increasing your stamina, willpower, and focus. Every
                            aspect of SuperBetter is designed to harness the
                            power of positive emotions and social connection to
                            help you improve your life.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                          What are the benefits of playing SuperBetter?
                          </Accordion.Header>
                          <Accordion.Body>
                            SuperBetter is a tool created by game designers and
                            backed by science. Playing SuperBetter helps build
                            personal resilience: the ability to stay strong,
                            motivated, and optimistic even in the face of
                            difficult challenges. Resilience has a powerful
                            effect on health—by boosting physical and emotional
                            well-being. Resilience also helps you achieve your
                            life goals—by strengthening your social support and
                            increasing your stamina, willpower, and focus. Every
                            aspect of SuperBetter is designed to harness the
                            power of positive emotions and social connection to
                            help you improve your life.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                          Are there studies backing SuperBetter?
                          </Accordion.Header>
                          <Accordion.Body>
                            SuperBetter is a tool created by game designers and
                            backed by science. Playing SuperBetter helps build
                            personal resilience: the ability to stay strong,
                            motivated, and optimistic even in the face of
                            difficult challenges. Resilience has a powerful
                            effect on health—by boosting physical and emotional
                            well-being. Resilience also helps you achieve your
                            life goals—by strengthening your social support and
                            increasing your stamina, willpower, and focus. Every
                            aspect of SuperBetter is designed to harness the
                            power of positive emotions and social connection to
                            help you improve your life.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">Test 2</Tab.Pane>
                    <Tab.Pane eventKey="third">Test 3</Tab.Pane>
                    <Tab.Pane eventKey="fourth">Test 4</Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Faq2;
