import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"
import Faq1 from "../sections/faq/faq-1"
import Faq2 from "../sections/faq/faq-2"

const FaqPage = () => {
  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions | FAQ"
        description="Here’s where you’ll find answers to commonly asked questions about SuperBetter. The FAQ includes how to play, the research, subscription plans and more. "
        keywords="SuperBetter FAQ"
      />
      <Faq1 />
      <Faq2 />
    </Layout>
  )
}

export default FaqPage
